/* In Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    color: white;
    /* padding: 10px; */
    position: fixed; /* Fixed positioning relative to the viewport */
    top: 0; /* Align to the top of the viewport */
    left: 0; /* Align to the left of the viewport */
    width: 100%; /* Ensure the navbar spans the full width of the viewport */
    z-index: 1000; /* Ensure the navbar is above other content */
    padding: -30px;
    margin-right: 20px; 
    
    /* ... other existing styles ... */
}

body {
    padding-top: 60px; /* Adjust this value based on the actual height of your navbar */
}

.navbar-brand,
.navbar-brand a { /* Ensure that both the div and the Link inside it are styled */
    font-size: 1.5em;
    color: white; /* This sets the text color to white */
    text-decoration: none; /* This removes underline from links */
    margin-right: 20px; 
    margin-left: 5px; 
}

.navbar-brand a:visited { /* Specifically targeting visited links */
    color: white; /* This will ensure visited links are also white */
}

.navbar-links a {
    color: white;
    margin: 10px 10px;
    text-decoration: none;
}

.navbar-links a:hover {
    text-decoration: underline;
}

.navbar-links .favorites-link {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #333; /* Use the same color as your navbar for consistency */
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.navbar-links .favorites-link:hover {
    background-color: #444; /* Slightly lighter than the normal state for hover */
}

.navbar-links a.favorites-icon {
    margin-left: 5px;
    color:white; /* Set the color of the heart icon to white */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .navbar-links .favorites-link {
        margin: 10px 0; /* Adjust margin for mobile */
    }
}

/* Style for the hamburger icon */
.hamburger {
    display: none; /* Hide by default and only show on small screens */
    font-size: 1.5em;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    margin-left: 20px; 
    margin-right: 20px; 
}

/* Hide the links on small screens and toggle visibility when expanded */
@media (max-width: 768px) {
    .navbar-links {
        display: none;
        flex-direction: column;
        width: 100%; /* Take full width */
        text-align: center; /* Center align the text */
    }

    .navbar-links.expanded {
        display: flex; /* Re-display when expanded */
    }

    .hamburger {
        display: block; /* Show the hamburger icon */
    }

}

/* In your Navbar.css or a relevant CSS file */
.link-button {
    background: none;
    color: rgb(92, 92, 235); /* Or the color of your choice */
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    text-decoration: underline;
    margin: 0; /* Adjust as needed */
    padding: 0.5em 1em; /* Adjust as needed */
    font-size: 1rem; /* Adjust as needed */
  }
  
  .link-button:hover,
  .link-button:focus {
    text-decoration: none;
    color: rgb(166, 166, 193); /* Or another color for hover state */
  }
  
