/* TermsAndConditions.css */

.terms-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    line-height: 1.6;
  }
  
  .terms-container h1, .terms-container h2 {
    color: #333;
  }
  
  .terms-container p {
    color: #666;
    margin-bottom: 15px;
  }
  