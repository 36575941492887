/* Privacy.css */

.privacy-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    line-height: 1.6;
  }
  
  .privacy-container h1, .privacy-container h2 {
    color: #333;
  }
  
  .privacy-container p {
    color: #666;
    margin-bottom: 15px;
  }
  


  