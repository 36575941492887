.about-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  line-height: 1.6;
}

.about-container h1, .terms-container h2 {
  color: #333;
}

.about-container p {
  color: #666;
  margin-bottom: 15px;
}
