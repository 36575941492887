video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  background: url('/public/images/roads.jpg') center center/cover no-repeat;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.hero-container>h2 {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -30%);
  z-index: 1;
  color: #fff;
  text-align: center;
  font-size: 4vw;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

.submit-button-container {
  display: flex;
  justify-content: center;
  /* Center the button */
  align-items: center;
  /* Add other styles as needed */
}

/* Search Bar Styles */
.search-bar-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.search-bar {
  padding: 10px 20px;
  font-size: 18px;
  border: 2px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
}

.search-button {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #00aaff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Body Styles Section */
.body-styles {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -20%);
  width: 100%;
  display: flex;
  justify-content: center;
  top: 10%;
  z-index: 10;
  padding: 2vh 0;
}

.body-style {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

}

.body-style img {
  /* Adjust as necessary */
  margin-bottom: 8px;
  width: 12vw;
  height: auto;
}

.body-style span {
  color: #fff;
  font-size: 16px;
}

.options-container {
  top: 60%;
  left: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translate(0%, 120%);
  padding: 2vw;

}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px 0;
  flex-wrap: wrap;
}

.duration-container,
.usage-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* Stack elements vertically */
  color: #fff;
  font-size: 16px;

}

/* For the range input and select box to have full width */
.duration-container input[type="range"],
.usage-container select,
.budget-container select {
  width: 80%;
  /* Full width for smaller screens */
}

/* .option-label {
  margin-right: 10px;
} */
.option-label {
  text-align: center;
  /* Center the text of the labels */
  width: 100%;
  /* Make labels take up full width */
  margin-bottom: 10px;
  color: #fff;
  /* Space between label and input/select */
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: baseline; /* Align the text baselines of all children */
  /* Adjust spacing as needed */
}

/* Budget Container Styles */
.budget-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-size: 16px;
  /* Space between this and the next section */
}

.budget-container input[type=range] {
  width: 80%;
  /* Adjust based on preference */
  margin: 10px 0;
  cursor: pointer;
}

.car-selection-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 2;
  /* This container should be larger to fit both dropdowns side by side */
}

.car-make-container,
.car-bodytype-container {
  flex: 1;
  /* Each takes up equal space */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.car-make-container select,
.car-bodytype-container select,
.budget-container select {
  width: 90%;
  padding: 10px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  border-radius: 5px;
  border: 2px solid #ddd;
  margin-top: 5px;
  cursor: pointer;
}


.duration-container,
.usage-container,
.budget-container {
  flex: 1;
  /* Take up equal space */
  display: flex;
  justify-content: center;
  align-items: center;
  /* Apply consistent styling to these elements */
}

.submit-button-container {
  width: 100%;
  /* Ensure it takes the full width of its container */
  display: flex;
  justify-content: center;
  /* Center the button horizontally */
  align-items: center;
  /* Center the button vertically */
  margin-top: auto;
  /* Push the button to the bottom */
}

.submit-btn {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #007bff;
  /* Use your primary button color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  /* Add further styling as necessary */
}

.loading-bar {
  height: 100%;
  background-color: #007bff;
  transition: width 0.5s ease;
}

@keyframes loading-animation {
  0% { width: 0; }
  100% { width: 100%; }
}

.loading .loading-bar {
  animation: loading-animation 10s linear infinite;
}

.submit-btn.loading {
  background-color: transparent; /* Make the button transparent when loading */
  color: transparent; /* Hide the text 'Ask the Wiz' when loading */
  border-color: #007bff; /* Optional: Change the border color during loading */
}

@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.blinking {
  animation: blink-animation 2s ease-in-out infinite;
}

.spinning {
  animation: spin 2s linear infinite;
}

.error-message {
  color: red;
  background-color: #ffc0cb; /* light pink background */
  padding: 10px;
  margin: 10px 0;
  border: 1px solid red;
  border-radius: 5px;
}


/*  */
/*  */
/*  */
/*  */
/* Phones in portrait */
@media (max-width: 1180px) {
  .hero-container {
    position: relative;
    /* Ensure the positioning context is set for children */
    height: 30vh;
    /* Set the height to take up half of the viewport height */
    /* ... other styles ... */
  }

  .hero-container>h2 {
    position: absolute;
    top: 20%;
    /* Adjust to center vertically within the .hero-container */
    left: 50%;
    transform: translate(-50%, -50%);
    /* ... other styles ... */
  }

  .body-styles {
    position: absolute;
    top: 0;
    /* Align to the top of the .hero-container */
    left: 50%;
    transform: translateX(-50%, -50%);
    ;
    width: 100%;
    /* Full width */
    height: 30%;
    /* Take up 30% of the hero-container's height */
    display: flex;
    justify-content: center;
    align-items: center;
    /* Center items vertically and horizontally */
    z-index: 10;
    /* ... other styles ... */
  }

  .body-style img {
    /* Adjust as necessary */
    margin-bottom: 8px;
    width: 25vw;
    height: auto;
  }

  .options-container {
    position: absolute;
    /* Change to absolute for positioning relative to hero-container */
    top: 50%;
    /* Adjust the top value to position it below the hero-container */
    left: 50%;
    transform: translate(-50%, -50%);
    /* Center the container horizontally and adjust it vertically */
    width: 80%;
    /* Set a max width or use a percentage of the viewport */
    /* ... other styles ... */
  }
}


/*  */
/*  */
/*  */
/*  */
/* Adjustments for much smaller screens within the same media query */
@media (max-width: 800px) {
  .hero-container {
    height: auto;
    /* Allow the content to determine the height */
    padding-top: 10vh;
    /* Give space at the top for the body styles */
    min-height: 95vh;
  }

  .body-styles {
    position: absolute;
    top: 0;
    /* Align to the top of the viewport */
    left: 50%;
    transform: translateX(-70%, -20%);
    width: 100%;
    /* Full width */
    height: 30vh;
    /* Take up 30% of the viewport height */
    /* ... other styles ... */
  }

  .options-container {
    position: absolute;
    top: 40%;
    /* You may need to adjust this percentage based on actual content */
    left: 50%;
    transform: translate(-50%, -30%);
    width: 95%;
    /* You might want to increase the width on smaller screens */
    /* ... */
  }

  .duration-container input[type="range"],
  .usage-container select,
  .budget-container select {
    width: 60%;
    /* Full width for smaller screens */
  }

  .checkbox-group {
    display: flex;
    flex-direction: column; /* Stack checkboxes vertically */
    align-items: flex-start; /* Align the checkboxes to the start of the container */
  }
  
  .checkbox-group label {
    display: flex;
    align-items: center; /* Vertically align checkbox and label text */
    gap: 10px; /* Adjust the space between checkbox and label text */
  }
  

}


@media (min-width: 1180px) and (max-width: 3000px) and (orientation: landscape) {
  .hero-container {
    /* Adjust styles for landscape orientation */
    height: 40vh; /* Example adjustment */
    /* ... other styles ... */
  }

  .hero-container>h2 {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* z-index: 1; */
    color: #fff;
    text-align: center;
    font-size: 2vw;  /*vanish*/
  }

  .body-styles {
    /* Adjust styles for landscape orientation */
    height: 20%; /* Example adjustment */
    /* ... other styles ... */
  }

  .body-style img {
    /* Adjust as necessary */
    margin-bottom: 8px;
    width: 12vw;
    height: auto;
  }


  .options-container {
    /* Adjust styles for landscape orientation */
    width: 100%; /* Example adjustment */
    height: 25%
    /* transform: translate(-50%, -65%); */
    /* ... other styles ... */
  }

}

/*  */
/*  */
/*  */
/*  */
/* Landscape adjustments for screens up to 1180px wide */
@media (max-width: 1180px) and (orientation: landscape) {
  .hero-container {
    /* Adjust styles for landscape orientation */
    height: 40vh; /* Example adjustment */
    /* ... other styles ... */
  }

  .hero-container>h2 {
    position: absolute;
    top: 18%;
    left: 50%;
    transform: translate(-50%, -30%);
    z-index: 1;
    color: #fff;
    text-align: center;
    font-size: 2vw;  /*vanish*/
  }

  .body-styles {
    /* Adjust styles for landscape orientation */
    height: 20%; /* Example adjustment */
    /* ... other styles ... */
  }

  .body-style img {
    /* Adjust as necessary */
    margin-bottom: 8px;
    width: 12vw;
    height: auto;
  }


  .options-container {
    /* Adjust styles for landscape orientation */
    width: 70%; /* Example adjustment */
    /* ... other styles ... */
  }

  /* ... other element styles for landscape orientation ... */
}


/*  */
/*  */
/*  */
/*  */
/* Landscape adjustments for screens up to 800px wide */
@media (max-width: 950px) and (orientation: landscape) {
  .hero-container {
    /* Adjust styles for landscape orientation */
    min-height: 70vh; /* Example adjustment */
    /* ... other styles ... */
  }


.hero-container>h2 {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -30%);
  z-index: 1;
  color: #fff;
  text-align: center;
  font-size: 0vw;  /*vanish*/
}

  .body-styles {
    /* Adjust styles for landscape orientation */
    height: 5vh; /* Example adjustment */
    /* ... other styles ... */
  }

  .body-style img {
    /* Adjust as necessary */
    margin-bottom: 8px;
    width: 8vw;
    height: auto;
  }

  .body-style span {
    color: #fff;
    font-size: 10px;
  }
  .options-container {
    /* Adjust styles for landscape orientation */
    width: 100%; /* Example adjustment */
    height: 20vh;
    position: absolute;
    top: 60%;
    height: auto;
    padding-bottom: 18vh;
    /* ... other styles ... */
  }

  /* Additional adjustments for landscape orientation */
  .duration-container input[type="range"],
  .usage-container select,
  .budget-container select {
    width: 70%; /* Example adjustment */
  }

  .duration-container,
  .usage-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* Stack elements vertically */
    color: #fff;
    font-size: 12px;
  
  }
  /* ... other element styles for landscape orientation ... */
}

/* Continue with other specific styles for landscape orientation as needed */


/*  */
/*  */
/*  */
/*  */
/* Landscape adjustments for screens up to 800px wide */
@media (max-width: 800px) and (orientation: landscape) {
  .hero-container {
    /* Adjust styles for landscape orientation */
    min-height: 70vh; /* Example adjustment */
    /* ... other styles ... */
  }


.hero-container>h2 {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -30%);
  z-index: 1;
  color: #fff;
  text-align: center;
  font-size: 1vw;
}

  .body-styles {
    /* Adjust styles for landscape orientation */
    height: 5vh; /* Example adjustment */
    /* ... other styles ... */
  }

  .body-style img {
    /* Adjust as necessary */
    margin-bottom: 8px;
    width: 12vw;
    height: auto;
  }

  .body-style span {
    color: #fff;
    font-size: 10px;
  }
  .options-container {
    /* Adjust styles for landscape orientation */
    width: 100%; /* Example adjustment */
    height: 20vh;
    position: absolute;
    top: 30%;
    height: auto;
    /* ... other styles ... */
  }

  /* Additional adjustments for landscape orientation */
  .duration-container input[type="range"],
  .usage-container select,
  .budget-container select {
    width: 70%; /* Example adjustment */
  }

  .duration-container,
  .usage-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* Stack elements vertically */
    color: #fff;
    font-size: 10px;
  
  }
  /* ... other element styles for landscape orientation ... */
}

/* Continue with other specific styles for landscape orientation as needed */