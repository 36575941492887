/* Base styles for the fixed navigation */
.vehicle-type-nav {
  position: fixed;
  top: 20px; /* Adjust this value as needed to move the nav down */
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  text-align: center;
}
/* Style for the dropdown select */
.vehicle-type-select {
  background-color: #232527;  /* Bootstrap's primary blue color */
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 10px; /* Larger font size for readability */
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 10px; /* Space below the select */
}

.vehicle-type-select:hover {
  background-color: #232527; /* A darker shade of blue for hover state */
}


/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .vehicle-type-nav {
    padding: 2rem; /* Further reduce padding for smaller screens */
  }

  .vehicle-type-select {
    display: block; /* Stack the links vertically on smaller screens */
    width: 100%; /* Full width links for easier touch */
    font-size: 12px; 
  /* Include padding in width calculations */
  }
}

/* Padding for the main content so it doesn't get hidden behind the nav */
