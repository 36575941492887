.users-table {
    width: 100%;
    border-collapse: collapse;
}

.users-table th, .users-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.users-table th {
    background-color: #f2f2f2;
}

.loading, .error {
    margin: 20px;
    font-size: 1.2em;
}
