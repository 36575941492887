/* Footer.css */

.footer {
    background-color: #333; /* Dark background for the footer */
    color: #f0f0f0; /* Slightly off-white text color for a softer look */
    padding: 20px 20px; /* Reduced top and bottom padding */
    font-family: 'Arial', sans-serif; /* A modern, readable font */
}

.footer-content {
    max-width: 1200px; /* Increased max-width for larger screens */
    margin: auto;
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center-align the content */
}

.footer-content p, .footer-content a {
    font-size: 0.95rem; /* Smaller font size for less important text */
    margin: 4px 0; /* Reduced margin for a tighter layout */
    color: #f0f0f0;
}

.footer-links {
    display: flex;
    justify-content: center; /* Center-align the links */
    flex-wrap: wrap; /* Allow the links to wrap on smaller screens */
    gap: 15px; /* Add space between links */
}

.footer-links a {
    color: #fff;
    text-decoration: none;
    font-weight: bold; /* Make links bold to stand out */
    font-size: 1rem; /* Slightly larger font size for links */
}

.footer-links a:hover {
    color: #4fa1f3; /* Highlight color for links on hover */
}

.about-section a {
    font-size: 0.95rem; /* Larger font size for the 'About' section for emphasis */
    font-weight: bold;
    margin-top: 20px; /* Add more space above the 'About' section */
    color: #f0f0f0;
    

}

.social-media {
    /* Add styles for social media icons here */
}

.newsletter {
    /* Add styles for newsletter subscription form here */
}

@media (max-width: 600px) {
    .footer {
        padding: 15px 10px; /* Even smaller padding for mobile */
    }
    .footer-content {
        padding: 10px; /* Reduced padding around the content */
    }

    .footer-links, .about-section, .social-media, .newsletter {
        margin-bottom: 15px;
    }

    .footer-links a, .about-section a {
        font-size: 0.9rem; /* Slightly smaller font size for mobile */
    }
}


.copyright {
    font-size: 0.85rem; /* Smaller font size for copyright text */
    margin-top: 30px; /* Add space before the copyright */
    opacity: 0.7; /* Lower opacity for a less prominent appearance */
}
