/* Existing styles in Home.css */
.home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
}

header, main, footer {
    width: 100%;
    /* Additional styling as needed */
}

.content {
    display: flex;
    justify-content: space-around;
    align-items: flex-start; /* Updated from 'start' to 'flex-start' */
}

@media (max-width: 768px) {
    .content {
        flex-direction: column;
    }
}

/* New style for image-container */
.image-container {
    max-width: 600px; /* Maximum width of the image container */
    width: 100%; /* Width relative to the parent container */
    margin: auto; /* Centers the container */
}

.image-container img {
    width: 100%; /* Makes the image responsive */
    height: auto; /* Maintains aspect ratio */
    max-width: 100%; /* Ensures the image doesn't exceed its natural size */
}
