/* In Search.css */
.search-container {
    margin: 20px;
    padding: 10px;
    text-align: center;
}

.search-container input {
    padding: 8px 15px;
    margin-right: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.search-container button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #010410;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.search-container button:hover {
    background-color: #0056b3;
}

/* Grid container for car cards */
.cars-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr)); /* Adjust minmax for your card size */
    gap: 20px; /* Space between cards */
    padding: 20px;
    justify-content: center;
}

/* Individual car card */
.car-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    width: 240px; /* Define a fixed width or use percentage */
    height: 320px; /* Define a height for your card */
    margin: 0 auto; /* Center the card if desired */
    cursor: pointer;
    perspective: 1000px; /* Necessary for the flip effect */
}

.car-card-inner {
    width: 100%;
    height: 100%;
    position: relative;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

/* The front and back faces of the card */
.car-card-front,
.car-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden; /* Hide the back of the flipped content */
}

/* Front face */
.car-card-front {
    z-index: 2; /* Ensure the front face is above the back face */
}

/* Back face, initially hidden */
.car-card-back {
    transform: rotateY(180deg); /* Flip the back face */
}

/* Flipped state styles */
.car-card.flipped .car-card-inner {
    transform: rotateY(180deg); /* Flip the card to show the back */
}


.car-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Image styling */
.car-card img {
    width: 100%;
    height: 180px; /* Adjust as needed */
    object-fit: cover; /* Adjust as needed */
}

/* Details section within card */
.car-card .car-details {
    padding: 10px;
    text-align: left;
}

.car-card h3 {
    margin: 0;
    color: #333;
    font-size: 1.2em;
}

.car-card p {
    margin: 5px 0;
    color: #666;
    font-size: 1em;
}

.car-inline-details {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.9em; /* You may adjust the font size if needed */
}

.car-inline-details span {
    margin-right: 10px; /* Adjust the right margin as needed */
}


/* Add responsive touch to grid */
@media (max-width: 768px) {
    .cars-container {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
}

/* Rest of your styles */

/* Image container */
.car-card .car-image {
    width: 100%;
    height: 180px; /* Fixed height for the image container */
    background-color: #f7f7f7; /* A light grey background to handle images with transparency */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Image styling */
.car-card img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* This ensures the image is contained within the element and retains aspect ratio */
}

/* Car info styling */
.car-card .car-info {
    padding: 10px;
    text-align: center; /* Center align the text for car info */
}

/* Car details styling */
.car-card .car-details {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); /* Create a grid layout for details */
    gap: 5px; /* Space between details */
    font-size: 0.9em; /* Smaller font size for details */
    text-align: center; /* Center align the details text */
}

/* Car name and model styling */
.car-card h3 {
    font-size: 1.1em;
    margin: 10px 0 5px 0; /* Spacing around the car name and model */
    white-space: nowrap; /* Ensure the name/model is on a single line */
    overflow: hidden;
    text-overflow: ellipsis; /* Add an ellipsis for overflow text */
}

.car-info h3 {
    margin-bottom: 16px; /* Adds space below the heading */
}

.car-info p {
    margin-bottom: 8px; /* Adds space between the paragraphs */
}

/* Remove the margin from the last paragraph to prevent extra space at the bottom */
.car-info p:last-child {
    margin-bottom: 0;
}


/* Responsive styling adjustments */
@media (max-width: 768px) {
    .car-card .car-details {
        grid-template-columns: 1fr; /* Stack details in a single column on smaller screens */
    }
}

/* In Search.css */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Adjust as needed */
}

/* Loading text or you can replace it with an actual spinner */
.loading-container::before {
    content: 'Loading...';
    font-size: 20px;
    color: #666;
}

/* Spinner styles */
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #007bff;

    display: flex;
    justify-content: center;
    align-items: center;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.car-info img {
    width: 80px; /* Adjust the size as needed */
    height: 80px; /* Adjust the size as needed */
    border-radius: 50%; /* This makes the image round */
    object-fit: cover; /* This will cover the area without stretching the image */
    display: block; /* Align the image properly */
    margin: 0 auto 16px; /* Centers the image horizontally and adds space below */
    background-color: white; /* Background color for the circular image */
    border: 3px solid white; /* Adjust border size and color as needed */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds shadow to the image */
}

/* Additional responsive adjustments as needed */

/* Add any additional specific styles for your car details here */
/* Additional styles for filters, sorting options, and results */
.filters-section {
    /* Placeholder for filters section styles */
}

.sorting-options {
    /* Placeholder for sorting options styles */
}

.search-results {
    /* Placeholder for search results styles */
}
